import _ from "lodash";
import moment from "moment";
import {NAME} from "./constants";

export const getSoldBySource = (state, measurement) => {
    const dailyStatsBySource = _.get(state[NAME], "dailySourceStats");
    const selected = dailyStatsBySource.map(item => {
        if (measurement.value === "ordersCount") {
            return {
                source: item.source,
                processed: item.processed,
                opened: item.received - item.processed,
                total: item.received
            };
        } else {
            return {
                source: item.source,
                processed: Number((Math.round(item.processedCharge * 100) / 100).toFixed(2)),
                opened: Number((Math.round((item.totalCharge - item.processedCharge) * 100) / 100).toFixed(2)),
                total: Number((Math.round(item.totalCharge * 100) / 100).toFixed(2))
            };
        }
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.source === item.source);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};
export const getSoldBySubSource = (state, measurement) => {
    const dailyStatsBySource = _.get(state[NAME], "dailySourceStats");
    const selected = dailyStatsBySource.filter(item => item.source === state[NAME].selectedSource).map(item => {
        if (measurement.value === "ordersCount") {
            return {
                subsource: item.subsource,
                processed: item.processed,
                opened: item.received - item.processed,
                total: item.received
            };
        } else {
            return {
                subsource: item.subsource,
                processed: Number((Math.round(item.processedCharge * 100) / 100).toFixed(2)),
                opened: Number((Math.round((item.totalCharge - item.processedCharge) * 100) / 100).toFixed(2)),
                total: Number((Math.round(item.totalCharge * 100) / 100).toFixed(2))
            };
        }
    });
    const grouped = [];
    selected.forEach(item => {
        const index = grouped.findIndex(el => el.subsource === item.subsource);
        if (index >= 0) {
            grouped[index].processed = Number((Math.round((grouped[index].processed + item.processed) * 100) / 100).toFixed(2));
            grouped[index].opened = Number((Math.round((grouped[index].opened + item.opened) * 100) / 100).toFixed(2));
            grouped[index].total = Number((Math.round((grouped[index].total + item.total) * 100) / 100).toFixed(2));
        } else {
            grouped.push(item);
        }
    });
    return grouped;
};

export const getDailySoldBySource = (state, measurement) => {
    const dailySourceStats = _.get(state[NAME], "sourceStatsWithDates", []);

    const result = [];

    dailySourceStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.source);
        if (index >= 0) {
            if (measurement.value === "ordersCount") {
                result[index].data.push(
                    {
                        x: moment(item.date).format("L"),
                        y: Number((item.received).toFixed(2))
                    });
            } else {
                result[index].data.push(
                    {
                        x: moment(item.date).format("L"),
                        y: Number((item.totalCharge).toFixed(2))

                    });
            }
        } else {
            if (measurement.value === "ordersCount") {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: moment(item.date).format("L"),
                            y: Number((item.received).toFixed(2))
                        }
                    ]
                });
            } else {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: moment(item.date).format("L"),
                            y: Number((item.totalCharge).toFixed(2))
                        }
                    ]
                });
            }
        }
    });
    return result;
};

export const getOrderTotalsByCountries = state =>
    _.get(state[NAME], "orderTotalsByCountries", {});

export const getOrderTotalsByCountriesLoading = state =>
    _.get(state[NAME], "orderTotalsByCountriesLoading", false);

export const getSoldBySourceYearly = (state, measurement) => {
    const yearlySourceStats = _.get(state[NAME], "yearlySourceStats.reportBySourceAndYear", []);

    const result = [];

    yearlySourceStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.source);
        if (index >= 0) {
            if (measurement.value === "ordersCount") {
                result[index].data.push(
                    {
                        x: item.year,
                        y: Number((item.received).toFixed(2))
                    });
            } else {
                result[index].data.push(
                    {
                        x: item.year,
                        y: Number((item.totalCharge).toFixed(2))

                    });
            }
        } else {
            if (measurement.value === "ordersCount") {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: item.year,
                            y: Number((item.received).toFixed(2))
                        }
                    ]
                });
            } else {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: item.year,
                            y: Number((item.totalCharge).toFixed(2))
                        }
                    ]
                });
            }
        }
    });
    return result;
};


export const getSoldBySourceMonthly = (state, measurement) => {
    const monthlySourceStats = _.get(state[NAME], "yearlySourceStats.reportBySourceYearAndMonth", []);

    const result = [];

    monthlySourceStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.source);
        if (index >= 0) {
            if (measurement.value === "ordersCount") {
                result[index].data.push(
                    {
                        x: moment.months(item.month - 1),
                        y: item.received
                    });
            } else {
                result[index].data.push(
                    {
                        x: moment.months(item.month - 1),
                        y: item.totalCharge

                    });
            }
        } else {
            if (measurement.value === "ordersCount") {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: moment.months(item.month - 1),
                            y: item.received
                        }
                    ]
                });
            } else {
                result.push({
                    id: item.source,
                    data: [
                        {
                            x: moment.months(item.month - 1),
                            y: item.totalCharge
                        }
                    ]
                });
            }
        }
    });
    return result;
};


//USERS
export const getUsersReportFor6MonthsStats = (state) => {
    const user6MonthStats = _.get(state[NAME], "user6MonthStats");
    const result = [];

    user6MonthStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.updatedBy);
        if (index >= 0) {
            result[index].data.push(
                {
                    x: item.date,
                    y: item.processedCount
                });
        } else {
            result.push({
                id: item.updatedBy,
                data: [
                    {
                        x: item.date,
                        y: item.processedCount
                    }
                ]
            });
        }
    });
    return result;
};
export const getUsersReportFor7DaysStats = (state) => {
    const user7DaysStats = _.get(state[NAME], "user7DaysStats");
    const result = [];

    user7DaysStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.updatedBy);
        if (index >= 0) {
            result[index].data.push(
                {
                    x: item.date,
                    y: item.processedCount
                });
        } else {
            result.push({
                id: item.updatedBy,
                data: [
                    {
                        x: item.date,
                        y: item.processedCount
                    }
                ]
            });
        }
    });
    return result;
};
export const getUsersReportFor12HoursStats = (state) => {
    const user12HoursStats = _.get(state[NAME], "user12HoursStats");
    const result = [];

    user12HoursStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.updatedBy);
        if (index >= 0) {
            result[index].data.push(
                {
                    x: moment(item.date).format("HH:mm"),
                    y: item.processedCount
                });
        } else {
            result.push({
                id: item.updatedBy,
                data: [
                    {
                        x: moment(item.date).format("HH:mm"),
                        y: item.processedCount
                    }
                ]
            });
        }
    });
    return result;
};


export const getUsersReportFor6MonthsStatsLoading = (state) => _.get(state[NAME], "user6MonthStatsLoading");
export const getUsersReportFor7DaysStatsLoading = (state) => _.get(state[NAME], "user7DaysStatsLoading");
export const getUsersReportFor12HoursStatsLoading = (state) => _.get(state[NAME], "user12HoursStatsLoading");
//USERS


export const getDailySourceStatsLoading = (state) => _.get(state[NAME], "dailySourceStatsLoading");
export const getSourceStatsWithDatesLoading = (state) => _.get(state[NAME], "sourceStatsWithDatesLoading");
export const getDashboardMeasurement = (state) => _.get(state[NAME], "dashboardMeasurement", "Please select");

export const getSelectedSource = state => state[NAME].selectedSource;

// SETTINGS

export const getSettings = state =>
    _.get(state[NAME], "settings");

// SETTINGS

// INVENTORY
const formatTooltips = item => {
    const toolTips = [];
    if (!!item.itemTitle) {
        toolTips.push(item.itemTitle);
    }
    if (!!item.categoryName) {
        toolTips.push(`Category: ${item.categoryName}`);
    }
    if (!!item.purchasePrice) {
        toolTips.push(`Purchase: ${item.purchasePrice}`);
    }
    if (!!item.retailPrice) {
        toolTips.push(`Retail: ${item.retailPrice}`);
    }
    return toolTips;
}

const mapWithToolTips = items =>
    _.map(items, i => ({ ...i, toolTips: formatTooltips(i) }));

export const getInventoryStatsForYear = state =>
    mapWithToolTips(_.get(state[NAME], "inventoryTop10ForYear.inventoryStatsModel", []));

export const getInventorySalesForYear = state =>
    mapWithToolTips(_.get(state[NAME], "inventoryTop10ForYear.inventoryCostStatsModel", []));

export const getInventoryStatsForMonth = state =>
    mapWithToolTips(_.get(state[NAME], "inventoryTop10ThisMonth.inventoryStatsModel", []));

export const getInventorySalesForMonth = state =>
    mapWithToolTips(_.map(_.get(state[NAME], "inventoryTop10ThisMonth.inventoryCostStatsModel", [])));

export const getInventoryTop10ForYearLoading = state =>
    _.get(state[NAME], "inventoryTop10ForYearLoading");

export const getInventoryTop10ThisMonthLoading = state =>
    _.get(state[NAME], "inventoryTop10ThisMonthLoading");

export const getInventorySoldStatsLoading = state =>
    _.get(state[NAME], "inventorySoldStatsLoading");

export const getInventoryCategories = state =>
    _.get(state[NAME], "inventoryCategoriesStats", []);



export const getInventorySoldStats = (state, measurement) => {
    const inventorySoldStats = _.get(state[NAME], "inventorySoldStats", []);

    const result = [];

    inventorySoldStats.forEach(item => {
        const index = result.findIndex(el => el.id === item.channelSKU);
        if (index >= 0) {
            result[index].data.push(
                {
                    x: moment(item.date).format("L"),
                    y: Number((item.totalSold).toFixed(2))

                });
        } else {
            result.push({
                id: item.channelSKU,
                data: [
                    {
                        x: moment(item.date).format("L"),
                        y: Number((item.totalSold).toFixed(2))
                    }
                ],
                toolTips: formatTooltips(item)
            });
        }
    });
    return result;
};

// INVENTORY


export const getDashboardLayout = state => state[NAME].layout;


export const isSendingFeedback = state => state[NAME].isSendingFeedback;
export const isSendingFeedbackMobile = state => state[NAME].isSendingFeedbackMobile;