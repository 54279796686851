import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { getUsersReportFor6Months, getUserStats7Days, getUserStats12hours } from "../actions";
import { Col, Grid, Row } from "react-flexbox-grid";
import Select from "react-select";
import * as selectors from "../selectors";
import LineChart from "./Linechart";
import { DASHBOARD_ITEMS, USER_ACTIONS } from "../constants";
import DahboardLayout from "./DahboardLayout";

const hourlyOptions = {
    day: {
        value: 0,
        label: "24 hrs"
    }, 
    week: {
        value: 1,
        label: "7 days"
    }
};

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSource: USER_ACTIONS[0],
            selectedHourlyOption: hourlyOptions.day
        };
    }

    UNSAFE_componentWillMount() {
        const { selectedSource, selectedHourlyOption } = this.state;
        this.props.getUsersReportFor6Months(selectedSource.value);
        this.props.getUserStats7Days(selectedSource.value);
        this.props.getUserStats12hours(selectedSource.value, selectedHourlyOption.value);
    }

    onSourceChange = (selected) => {
        this.setState({ selectedSource: selected }, () => {
            const { selectedHourlyOption } = this.state;
            this.props.getUsersReportFor6Months(selected.value);
            this.props.getUserStats7Days(selected.value);
            this.props.getUserStats12hours(selected.value, selectedHourlyOption.value);
        });
    };

    onHourlyOptionSelected = o => {
        this.setState({ selectedHourlyOption: o }, () => {
            const { selectedSource: selected } = this.state;
            this.props.getUserStats12hours(selected.value, o.value);
        });
    }

    render() {
        const { usersReportFor6MonthsStats, usersReportFor7DaysStats, usersReportFor12HoursStats, usersReportFor6MonthsStatsLoading, usersReportFor7DaysStatsLoading, usersReportFor12HoursStatsLoading } = this.props;
        const { selectedSource } = this.state;

        return (
            <Grid fluid className="dashboardContainer">

                <p><b>Please select User Activity to show</b></p>
                <Row>
                    <Col md={3}>
                        <Select options={USER_ACTIONS}
                            onChange={this.onSourceChange}
                            placeholder={"Select Type"}
                            value={selectedSource}
                        />
                    </Col>
                </Row>
                <DahboardLayout>
                    <div key={DASHBOARD_ITEMS.Users1}>
                        <LineChart data={usersReportFor6MonthsStats} title={selectedSource.label + " (last 6 months)"}
                            textDisplayKey="id"
                            legendLeftText={selectedSource === "ORDER_PROCESSED_VALUE" ? "Processed Value (£)" : "Processed Count"}
                            customColors={{ scheme: "dark2" }}
                            bottomLegend={"Month"}
                            loading={usersReportFor6MonthsStatsLoading}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Users2}>
                        <LineChart data={usersReportFor7DaysStats} title={selectedSource.label + " (last 14 days)"}
                            textDisplayKey="id"
                            legendLeftText={"Processed Count"}
                            customColors={{ scheme: "accent" }}
                            bottomLegend={"Date"}
                            loading={usersReportFor7DaysStatsLoading}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Users3}>
                        <LineChart data={usersReportFor12HoursStats} title={selectedSource.label}
                            textDisplayKey="id"
                            legendLeftText={"Processed Count"}
                            customColors={{ scheme: "dark2" }}
                            bottomLegend={"Time"}
                            loading={usersReportFor12HoursStatsLoading}
                            options={_.map(hourlyOptions, x => x)}
                            selectedOption={this.state.selectedHourlyOption}
                            onOptionSelected={this.onHourlyOptionSelected}
                        />
                    </div>
                </DahboardLayout>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const usersReportFor6MonthsStats = selectors.getUsersReportFor6MonthsStats(state);
    const usersReportFor7DaysStats = selectors.getUsersReportFor7DaysStats(state);
    const usersReportFor12HoursStats = selectors.getUsersReportFor12HoursStats(state);
    const usersReportFor6MonthsStatsLoading = selectors.getUsersReportFor6MonthsStatsLoading(state);
    const usersReportFor7DaysStatsLoading = selectors.getUsersReportFor7DaysStatsLoading(state);
    const usersReportFor12HoursStatsLoading = selectors.getUsersReportFor12HoursStatsLoading(state);
    return {
        usersReportFor6MonthsStats,
        usersReportFor7DaysStats,
        usersReportFor12HoursStats,
        usersReportFor6MonthsStatsLoading,
        usersReportFor7DaysStatsLoading,
        usersReportFor12HoursStatsLoading
    };
}

export default connect(mapStateToProps, {
    getUsersReportFor6Months,
    getUserStats7Days,
    getUserStats12hours
})(Users);
