import createActionType from '../core/createActionType';
import { NAME } from './constants';

export const GET_SOURCE_STATS_BE_DATE_RANGE = createActionType(`${NAME}/GET_SOURCE_STATS_BE_DATE_RANGE`);
export const GET_DAILY_SOURCE_STATS_BE_DATE_RANGE = createActionType(`${NAME}/GET_DAILY_SOURCE_STATS_BE_DATE_RANGE`);
export const GET_ORDERS_TOTALS_BY_COUNTRIES = createActionType(`${NAME}/GET_ORDERS_TOTALS_BY_COUNTRIES`);
export const GET_YEARLY_SOURCE_STATS  = createActionType(`${NAME}/GET_YEARLY_SOURCE_STATS`);
export const SOURCE_CHANGED  = createActionType(`${NAME}/SOURCE_CHANGED`);
export const MEASUREMENT_CHANGED  = createActionType(`${NAME}/MEASUREMENT_CHANGED`);



//USERS
export const GET_6_MONTH_USER_STATS  = createActionType(`${NAME}/GET_6_MONTH_USER_STATS`);
export const GET_7_Days_USER_STATS  = createActionType(`${NAME}/GET_7_Days_USER_STATS`);
export const GET_12_HOURS_USER_STATS  = createActionType(`${NAME}/GET_12_HOURS_USER_STATS`);
//USERS


// SETTINGS
export const GET_SETTINGS = createActionType(`${NAME}/GET_SETTINGS`);
export const SET_SETTINGS = createActionType(`${NAME}/SET_SETTINGS`);
// SETTINGS

// INVENTORY
export const GET_INVENTORY_TOP_10_FOR_YEAR = createActionType(`${NAME}/GET_INVENTORY_TOP_10_FOR_YEAR`);
export const GET_INVENTORY_TOP_10_THIS_MONTH = createActionType(`${NAME}/GET_INVENTORY_TOP_10_THIS_MONTH`);
export const GET_INVENTORY_SOLD_STATS = createActionType(`${NAME}/GET_INVENTORY_SOLD_STATS`);
export const GET_INVENTORY_CATEGORIES = createActionType(`${NAME}/GET_INVENTORY_CATEGORIES`);

// INVENTORY



export const GET_DASHBOARD_LAYOUT  = createActionType(`${NAME}/GET_DASHBOARD_LAYOUT`);
export const UPDATE_DASHBOARD_LAYOUT = createActionType(`${NAME}/UPDATE_DASHBOARD_LAYOUT`);


export const SEND_FEEDBACK = createActionType(`${NAME}/SEND_FEEDBACK`);
export const SEND_FEEDBACK_MOBILE = createActionType(`${NAME}/SEND_FEEDBACK_MOBILE`);