import axios from 'axios';

export const endpoints = {
    sourceStatsByDateRange: (start, end) => `linnworksDashboard/getBySourceAndSubsource/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}`,
    dailySourceStatsByDateRange: (start, end) => `linnworksDashboard/getDailyReportBySourceAndSubsource/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}`,
    ordersTotalsByCountries: (start, end) => `linnworksDashboard/countries-totals/${start.format('MM-DD-YYYY')}/${end.format('MM-DD-YYYY')}`,
    yearlySourcesStats: () => 'linnworksDashboard/getYearlySourcesStats',
    userStats6months: type => `linnworksDashboard/getUserStats6months/${encodeURI(type)}`,
    userStats7Days: type => `linnworksDashboard/getUserStats7Days/${encodeURI(type)}`,
    userStats12hours: (type, option) => `linnworksDashboard/getUserStats12hours?typeOfValues=${encodeURI(type)}&option=${option}`,
    settings: () => "settings",

    inventoryTop10ForYear: () => 'linnworksDashboard/getInventoryTop10ForYear',
    inventoryTop10ThisMonth: () => 'linnworksDashboard/getInventoryTop10ThisMonth',
    inventorySoldStats: () => 'linnworksDashboard/getInventorySoldStats',
    inventoryCategories: () => 'linnworksDashboard/getInventoryCategories',
    
    dashboardLayout: () => 'settings/dashboard-items',

    feedback: () => 'feedback',
    feedbackMobile: () => 'feedback/mobile',
};

export const getSourceStatsByDateRange = (start, end) => {
    return axios.get(endpoints.sourceStatsByDateRange(start, end));
};

export const getDailySourceStatsByDateRange = (start, end) => {
    return axios.get(endpoints.dailySourceStatsByDateRange(start, end));
};

export const getOrdersTotalsByCountries = (start, end) =>
    axios.get(endpoints.ordersTotalsByCountries(start, end));

export const getYearlySourcesStats = params => {
    return axios.get(endpoints.yearlySourcesStats(), { ...params });
};


export const getUsersReportFor6Months = (type) => {
    return axios.get(endpoints.userStats6months(type), {});
};

export const getUserStats7Dayss = type => {
    return axios.get(endpoints.userStats7Days(type), {});
};

export const getUserStats12hours = (type, option) => {
    return axios.get(endpoints.userStats12hours(type, option), {});
};




export const getSettings = () => 
    axios.get(endpoints.settings());

export const setSettings = data =>
    axios.put(endpoints.settings(), data);



export const getInventoryTop10ForYear = (type, categoryName) =>
    axios.get(endpoints.inventoryTop10ForYear(), { params: { type, categoryName } });

export const getInventoryTop10ThisMonth = (type, categoryName) =>
    axios.get(endpoints.inventoryTop10ThisMonth(), { params: { type, categoryName } });

export const getInventorySoldStats = (from, to, type, categoryName) =>
    axios.get(endpoints.inventorySoldStats(from, to), { params: { from: from.format('MM-DD-YYYY'), to: to.format('MM-DD-YYYY'), type, categoryName } });

export const getInventoryCategories = () =>
    axios.get(endpoints.inventoryCategories());

export const dashboardLayout = () => {
    return axios.get(endpoints.dashboardLayout());
};
export const updateDashboardLayout = data => {
    return axios.put(endpoints.dashboardLayout(), data);
};



export const sendFeedback = data => {
    return axios.post(endpoints.feedback(), data);
};
export const sendFeedbackMobile = data => {
    return axios.post(endpoints.feedbackMobile(), data);
};